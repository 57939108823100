import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Über mich</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Informationen über mich
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Sali, ich bin{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                Marc Welz
              </span>
            </h2>
            <p className="text-5">
            Seit ich mit 12 Jahren die Grafikkarte von unserem Familiencomputer ausgetauscht habe
            faszinieren mich Computer. Meine Ausbildung hat es mir ermöglicht, in die Welt der 
            Softwareentwicklung einzutauchen. Ich liebe es neue Technologien zu erlernen sowie mein 
            Wissen und eigene Ideen einzubringen. Dies mit dem Ziel das bestmögliche Produkt zu entwickeln. 
            Meine Interessen beschränken sich nicht ausschliesslich auf technische Themen:
            Es bereitet mir Freude, zwischenmenschliche Beziehungen zu pflegen und mich in neuen, herausfordernden Situationen zu beweisen.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  20
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Mehr als <span className="fw-700">4 Jahren</span> Erfahrung in der Softwareentwicklung
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-dark fw-600 mb-0">Marc Welz</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href="mailto:kontakt@marc-welz.ch">
                kontakt@marc-welz.ch
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Mein Geburtstag:</p>
            <p className="text-4 text-dark fw-600 mb-0">Juli, 2003</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">Ich bin von:</p>
            <p className="text-4 text-dark fw-600 mb-0">Thayngen, Schweiz</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
