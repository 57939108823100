import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  const faqsData = [
    {
      question: "Was für Projekte habe ich umgesetzt?",
      answer:
        "Während meiner Ausbildung habe ich diverse kleine Projekte für Lernzwecke umgesetzt. " +
        '. ' +
        "<ul>" +
        "<li>Minecraft Server Handler mit Vue.js & Deno.js</li>" +
        '<li>Mit Wordpress kleine Webseiten erstellt (<a href="https://svpreiat.ch">svpreiat.ch</a>)</li> '+
        '<li>Mitgewirkt bei der Entwicklung einer ' +
        '<a href="https://axa-ch-webhub-cloud.github.io/plib-feature/develop/?path=/story/welcome--to-pattern-library">Komponenten Bibliothek</a></li>' +
        '<li>Feature-Toggle in Java & Kotlin</li>' +
        "</ul>",
    },
    {
      question: "Welche Erfahrung habe ich in meinem Berufsfeld?",
      answer:
        "Ich habe in meiner Ausbildung in diversen agilen Teams mitgearbeitet. Dabei habe ich an verschiedenen Projekten mitentwickelt " +
        "und ich habe gelernt, mich an neue Entwicklungsumgebungen schnell einzuarbeiten und mich auf neue Situationen einzustellen.",
    },
    {
      question: "Habe ich Auszeichnungen oder Zertifikate in meinem Bereich erhalten?",
      answer:
        "<ul>" +
        "<li>Eidgenössisches Fähikeitszeugnis (EFZ) als Informatiker mit der Note 5</li>" +
        "</ul>",
    },/*
    {
      question: "Wo setze ich mich in meiner Freizeit ein?",
      answer:
        "<ul>" +
        '<li>Spieler UHC Black Sticks Embrach U21</li>' +
        '<li style={{font-weight: "400"}}>Mitglied Junge SVP Schaffhausen</li> '+
        '<li>Trainer Unihockeyverein "Reiat Stürmer"</li>' +
        '<li>Vorstandsmitglied Kinder- & Jugendkomission der Gemeinde Thayngen.</li>' +
        "</ul>",
    },*/
    {
      question: "Welche Sprachen beherrsche ich?",
      answer:
        "<ul>" +
        "<li>Deutsch (Muttersprache)</li>" +
        "<li>Englisch (B2)</li>" +
        "<li>Französisch (A2)</li>" +
        "</ul>"
    }
  ];

  return (
    <section id="faq" className="section bg-light">
      <div className="container">
        <div className="row gy-5">
          <div className="col-lg-6 order-1 order-lg-0 wow fadeInUp">
            {/* Heading */}
            <p className="text-center text-lg-start mb-2">
              <span className="bg-primary text-dark px-2">FAQ</span>
            </p>
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Haben Sie Fragen?
            </h2>
            {/* Heading end*/}

            <Accordion flush defaultActiveKey="0">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          <div
            className="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn"
            data-wow-delay="1s"
          >
            {" "}
            <img
              className="img-fluid"
              src="images/faq.png"
              title="FAQ"
              alt="faq"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
